import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label } from "reactstrap";
import { showError, showSuccess } from "helpers/utilHelper";
import { doUserFormCleanup, saveAuthUserPwd } from "store/actions";
import { ValidationException } from "helpers/errorHelper";
import PasswordInput from "components/Shared/PasswordInput";
import { getNewPasswordSchema, passwordChecks, validatePassword } from "helpers/validationHelper";
import classnames from "classnames";

const UserPasswordForm = props => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { isBusy, saved, saveError } = useSelector(state => state.User.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    oldPasswd: "",
    passwd: "",
    passwdConf: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: formInitialValues,
    // we need to use a `validate` function instead of validationSchema to set abortEarly to `false` and run all validators
    validate: validatePassword(Yup.object({
      oldPasswd: Yup.string().trim().required("Field is required."),
      ...getNewPasswordSchema()
    })),
    onSubmit: values => dispatch(saveAuthUserPwd(values, props.id)),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      // the Form component does not have much redux state
      // but we need to clear any server validation errors
      dispatch(doUserFormCleanup());
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-user attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess("Password has been changed");
      navigate(props.successRoute);
    } else if (saved === false) {
      showError("Unable to update password");
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field and reset field to initial
        formik.setFieldValue("oldPasswd", formInitialValues.oldPasswd)
        formik.setFieldTouched("oldPasswd", false);
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
    formik.setFieldTouched(name);
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{props.title}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}>
                {isBusy && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isBusy && <i className="mdi mdi-check me-1" />}
                Save password
              </Button>
              <Link to={props.cancelRoute} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-5">
                <Label className="col-sm-3 col-form-label">Current password</Label>
                <Col sm={9}>
                  <PasswordInput name="oldPasswd" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.oldPasswd} />
                  <small className={classnames("form-text password-check", { passed: !!formik.values.oldPasswd && !formik.errors.oldPasswd?.length })}>
                    <i className="mdi mdi-check-bold" /> Fill in the current password.
                  </small>
                  {!!saveError?.fields?.oldPasswd && !formik.touched.oldPasswd && <small className="form-text password-check failed">
                    <i className="mdi mdi-close-thick" /> Password is incorrect.
                  </small>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">New password</Label>
                <Col sm={9}>
                  <PasswordInput name="passwd" onChange={formik.handleChange} value={formik.values.passwd} />
                  <div className="password-check-list">
                    {passwordChecks.map(check => {

                      const failed = formik.errors.passwd?.includes(check);
                      const passed = formik.values.passwd && !failed;

                      return <small key={check} className={classnames("form-text m-0 password-check", { passed })}>
                        <i className="mdi mdi-check-bold" /> {check}
                      </small>
                    })}
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Confirm password</Label>
                <Col sm={9}>
                  <PasswordInput name="passwdConf" onChange={formik.handleChange} value={formik.values.passwdConf} />
                  <small className={classnames("form-text password-check", { passed: !!formik.values.passwdConf && !formik.errors.passwdConf?.length })}>
                    <i className="mdi mdi-check-bold" /> Passwords must match.
                  </small>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

UserPasswordForm.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  successRoute: PropTypes.string,
  cancelRoute: PropTypes.string,
};

export default UserPasswordForm;
import React, { useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, Label, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import MetaTitle from "components/Shared/MetaTitle";
import { activateAccount, doAccountActivationCleanup, doJwtValidationCleanup, preActivateAccount } from "store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo-simple.svg";
import { route, routes } from "helpers/routeHelper";
import { ACCOUNT_ALREADY_ACTIVATED, BeException, EMAIL_VERIFICATION_INVALID_TOKEN, USER_NOT_FOUND, ValidationException } from "helpers/errorHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import successIcon from "assets/images/check-round.svg";
import errorIcon from "assets/images/x-round.svg";
import TermsOfServiceModal from './Partial/TermsOfServiceModal';
import PasswordInput from 'components/Shared/PasswordInput';
import classnames from "classnames";
import { validatePassword, passwordChecks, getNewPasswordSchema } from 'helpers/validationHelper';

const ActivateAccount = () => {

  const { token } = useParams();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      passwd: "",
      passwdConf: "",
    },
    // we need to use a `validate` function instead of validationSchema to set abortEarly to `false` and run all validators
    validate: validatePassword(Yup.object({
      ...getNewPasswordSchema()
    })),
    onSubmit: values => {
      setError(null);
      dispatch(activateAccount(token, values));
    },
  });

  /********** STATE **********/

  const { isValidationInProgress, valid, validationError, payload } = useSelector(state => state.User.ValidateJwt);
  const { isActivationInProgress, activated, activationError } = useSelector(state => state.User.ActivateAccount);

  const [error, setError] = useState(null);
  const [termsModal, showTermsModal] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(preActivateAccount(token));
    return () => {
      dispatch(doJwtValidationCleanup());
      dispatch(doAccountActivationCleanup());
    }
  }, [token]);

  // runs whenever the 'valid' flag changes
  // which happens after a validate-jwt attempt
  useEffect(() => {
    if (valid === false) {
      formik.setSubmitting(false);
      let errMessage = 'Unable to validate activation link';
      if (validationError instanceof BeException) {
        switch (validationError.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = 'This link is invalid or has expired';
            break;
          case USER_NOT_FOUND:
            errMessage = 'User account not found';
            break;
          case ACCOUNT_ALREADY_ACTIVATED:
            errMessage = 'This account is already activated';
            break;
        }
      }
      setError(errMessage);
    }
  }, [valid]);

  // runs whenever the 'activated' flag changes
  // which happens after an activate-account attempt
  useEffect(() => {
    if (activated === false) {
      formik.setSubmitting(false);
      // see if the action failed due to validation
      if (activationError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(activationError.fields)) {
          formik.setFieldError(name, message);
        }
        return;
      }
      let errMessage = 'Unable to activate account';
      if (activationError instanceof BeException) {
        switch (activationError.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = 'This link is invalid or has expired';
            break;
          case USER_NOT_FOUND:
            errMessage = 'User account not found';
            break;
          case ACCOUNT_ALREADY_ACTIVATED:
            errMessage = 'This account is already activated';
            break;
        }
      }
      setError(errMessage);
    }
  }, [activated]);

  /********** EVENT HANDLERS **********/

  const openTerms = () => {
    showTermsModal(true);
  }

  const toggleTerms = () => {
    showTermsModal(current => !current);
  }

  return <React.Fragment>
    <MetaTitle>Activate Account</MetaTitle>
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col xs={7}>
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Mavsign Registration</h5>
                      <p>Secure your account with a strong password</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to={route(routes.home)} className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} height="44" />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  {/* Visible when token validation request is in progress */}
                  {isValidationInProgress && <SpinnerChase className="mt-1 mb-3" />}
                  {/* Visible when initial token validation has failed */}
                  {!valid && !!error && <div className="text-center">
                    <img src={errorIcon} />
                    <h4 className="mt-4 mb-3">Activation Failed</h4>
                    <p className="text-muted">{error}</p>
                  </div>}
                  {/* Visible when account activation is successfull */}
                  {activated && <div className="text-center">
                    <img src={successIcon} />
                    <h4 className="mt-4 mb-3">Registration Successful</h4>
                    <p className="text-muted">Your account has been activated successfully.<br />You can now login to Mavsign</p>
                    <Link to={route(routes.home)} className="btn btn-primary d-block">Login</Link>
                  </div>}
                  {/* Visible when initial token validation is successful but account activation is not complete */}
                  {valid && !activated && <Form
                    noValidate
                    className="form-horizontal"
                    onSubmit={formik.handleSubmit}>

                    {/* Visible when account activation has failed */}
                    {!!error && <Alert color="danger">{error}</Alert>}

                    <div className="mb-3">
                      <Label className="form-label">Email address</Label>
                      <Input
                        type="text"
                        name="email"
                        className="form-control"
                        value={payload.email}
                        disabled={true} />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <PasswordInput
                        name="passwd"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwd} />
                      <div className="password-check-list">
                        {passwordChecks.map(check => {

                          const failed = formik.errors.passwd?.includes(check);
                          const passed = formik.values.passwd && !failed;

                          return <small key={check} className={classnames("form-text m-0 password-check", { passed })}>
                            <i className="mdi mdi-check-bold" /> {check}
                          </small>
                        })}
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Confirm password</Label>
                      <PasswordInput
                        name="passwdConf"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwdConf} />
                      <small className={classnames("form-text password-check", { passed: !!formik.values.passwdConf && !formik.errors.passwdConf?.length })}>
                        <i className="mdi mdi-check-bold" /> Passwords must match.
                      </small>
                    </div>

                    <div className="mt-3 d-grid">
                      <Button type="submit" color="primary" className="btn-block" disabled={isActivationInProgress || !(formik.isValid && formik.dirty)}>
                        {/* Visible when account activation request is in progress */}
                        {isActivationInProgress && <i className="mdi mdi-spin mdi-loading me-2" />}
                        Activate account
                      </Button>
                    </div>

                    <div className="mt-4 text-center">
                      <p className='text-center mb-0'>By registering you agree to the Mavsign</p>
                      <button onClick={openTerms} className="primary text-center btn btn-link p-0">Terms and Conditions</button>.
                    </div>
                  </Form>}
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>Already have an account? <Link to={route(routes.login)} className="fw-medium text-primary">Login</Link></p>
              <p>© {new Date().getFullYear()} Maverick Signings</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <TermsOfServiceModal isOpen={termsModal} toggle={toggleTerms} />
  </React.Fragment>
}

export default ActivateAccount;

import * as Yup from "yup";
import regx from "constants/regx";

export const passwordChecksMap = {
  MIN_CHARACTERS: "At least 8 characters",
  ONE_LOWERCASE: "At least 1 lowercase character",
  ONE_UPPERCASE: "At least 1 uppercase character",
  ONE_NUMBER: "At least 1 number",
  ONE_SPECIAL: "At least 1 special character",
};

// array with all the password checks
export const passwordChecks = Object.values(passwordChecksMap);

// contains all validations needed for creating / updating a password
export const getNewPasswordSchema = (passwdKey = "passwd") => ({
  [passwdKey]: Yup.string().trim()
    .min(8, passwordChecksMap.MIN_CHARACTERS)
    .matches(regx.oneLowercase, passwordChecksMap.ONE_LOWERCASE)
    .matches(regx.oneUppercase, passwordChecksMap.ONE_UPPERCASE)
    .matches(regx.oneSpecial, passwordChecksMap.ONE_SPECIAL)
    .matches(regx.oneNumber, passwordChecksMap.ONE_NUMBER),
  [`${passwdKey}Conf`]: Yup.string().trim().required("Field is required.")
    .oneOf([Yup.ref(passwdKey)], "Password confirmation does not match."),
});

// set abortEarly to false to run all validators
export const validatePassword = schema =>
  values => schema.validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(({ inner }) => (
      // reduce all error messages for a field to an array
      inner.reduce((memo, { path, message }) => ({
        ...memo,
        [path]: (memo[path] || []).concat(message),
      }), {})
    ));
